.hero {
    min-height: 500px;
    background-color: var(--white);
    justify-content: center;
    text-align: center;
    background: linear-gradient(125deg, var(--purple), var(--aqua));
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    gap: 30px;
    padding: 30px;
}

.hero-header {
    color: white;
}

.hero-header h1 {
    display: flex;
    align-items: center;
    justify-content: center; /* Optional, if you want to center the content horizontally */
    gap: 8px; /* Adds space between the text and the icon */
}
  
.hero-header .hero-header-icon {
    margin-left: 5px; /* Optional, to control spacing between the icon and the text */
}

.hero-image {
    padding-left: 100px;
}

.hero-image img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border: 5px solid white;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    border-radius: 150px;
}

.hero-header p {
    font-size: 1.1rem;
    margin-top: 20px;
}

.hero-header a {
    color: var(--white);
}

.hero-header h1 {
    font-weight: 400;
    color: var(--black);
    font-size: 2.7rem;
}

.hero-header h1 span {
    font-weight: 600;
    color: var(--white);
}

.hero-header-icon {
    color: var(--white);
}

@media screen and (max-width: 768px) {
    .hero {
        padding: 30px;
    }

    .hero-header h1 {
        font-size: 2.2rem;
        margin-top: 10px;
    }

    .hero-header h1:first-of-type {
        margin-top: 0px;
    }
}

@media screen and (max-width: 1000px) {
    .hero-image {
        display: none;
    }
}

@media (max-width: 400px) {
    .hero-header h1 {
        font-size: 1.9rem;
    }
}