.card-price {
    font-size: 1.1rem;
    font-weight: 600;
    background-color: var(--white);
    width: fit-content;
    margin: auto;
    padding: 5px 10px;
    border-radius: 5px;
    color: var(--black);
}

.card-price p {
    margin: 0;
}

.services-container .card-text {
    border-top: 1px solid var(--white);
    padding-top: 15px;
    margin-top: 20px;
    color: var(--white);
}

.services-container .card {
    border: none !important;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    background: linear-gradient(125deg, var(--purple), var(--aqua));
}

.services-container .card h4 {
    color: white;
}