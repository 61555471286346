.about-container {
    padding: 30px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.about-container a {
    color: var(--dark-purple);
}

.about-header {
    padding-bottom: 20px;
    margin-bottom: 15px;
    font-weight: 500;
    color: var(--black);
    text-align: center;
}

.about-header h3 {
    font-weight: 600;
}

.about-icon {
    margin-right: 10px;
}

.about-header h2 {
    font-size: 1.75rem;
}

.about-header p {
    font-size: 1.1rem;
    margin-bottom: 0px;
    font-weight: normal;
}