.contact-container {
    display: flex;
    padding: 30px;
    align-items: center;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.contact-form-container {
    width: 40%;
}

.contact-testimonial {
    width: 60%;
    text-align: center;
    padding: 40px 0px 40px 40px;
}

.contact-testimonial h4 {
    font-size: 1.15rem;
}

.contact-testimonial h4:first-of-type {
    color: var(--black);
    font-weight: 400;
    margin-top: 40px;
    font-style: italic;
}

.contact-testimonial h4:last-of-type {
    color: var(--blue);
    font-weight: 600;
    margin-top: 15px;
}

.contact-testimonial img {
    width: 180px;
    height: 180px;
    border-radius: 90px;
    border: 5px solid white;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    .contact-container {
        flex-direction: column;
    }

    .contact-form-container,
    .contact-testimonial {
        width: 100%;
    }

    .contact-testimonial {
        margin-top: 30px;
        padding: 30px;
    }
}