.card-container {
    padding: 0px;
}

.card-title {
    font-weight: 600;
}

.card {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    height: 100%;
    border: none !important;
}

.about-container .card h4 {
    border-bottom: 1px solid var(--grey);
    padding-bottom: 15px;
}

.card-img-container {
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
}
  
.card-img-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    .col-md-4:not(:last-child) {
        margin-bottom: 1.5rem;
    }
}