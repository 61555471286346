:root {
  --aqua: #01b1b7ff;
  --blue: #4ea5bdff;
  --purple: #b3b2d2;
  --dark-purple: #797895;
  --white: #f8f8f8;
  --grey: #d4d4d4;
  --black: #252525;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Poppins", serif;
  overscroll-behavior: none;
}

html, body {
  background-color: var(--white);
}

h2, h3, h4, h5 {
  font-weight: 600;
  color: var(--blue);
}

.btn-primary {
  background-color: var(--dark-purple);
  color: var(--white);
  border: none;
  font-weight: 500;
}

.btn-primary:hover {
  background-color: var(--purple);
  color: var(--black);
}

.btn-primary:active {
  background-color: var(--purple) !important;
  color: var(--black);
  border-color: var(--white);
}

.form-control:focus {
  border-color: var(--purple); /* Change this to your desired color */
  box-shadow: 0 0 0 0.25rem var(--white); /* Optional: for glowing effect */
}