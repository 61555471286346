/* Basic styling */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--black);
    padding: 10px 20px;
    height: 100px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.navbar-logo {
    display: flex;
    align-items: center;
}

.navbar-logo-icon {
    margin-right: 20px;
    padding: 10px 12px;
    color: var(--white);
    background: linear-gradient(125deg, var(--purple), var(--aqua));
    border-radius: 10px;
}

.navbar-logo-text {
    border-left: 1px solid var(--grey);
    padding-left: 20px;
    text-align: left;
}
  
.navbar-logo h2 {
    font-weight: 600;
    font-size: 2.5rem;
    margin-bottom: 0px;
    font-family: "Rouge Script", cursive;
    color: var(--black);
}

.navbar-logo p {
    font-weight: 400;
    font-size: .9rem;
    margin-bottom: 0;
}
  
.navbar-links {
    color: var(--black);
    display: flex;
}
  
.navbar-links a {
    color: var(--black);
    text-decoration: none;
    transition: color 0.3s ease;
    font-weight: 500;
}

.navbar-links a:hover {
    color: var(--dark-purple);
}

.navbar-link-icon {
    margin-right: 8px;
}

.navbar-links a:not(:last-child) {
    margin: 20px;
}

.navbar-links a:last-child {
    margin: 20px 10px 20px 20px;
}
  
.navbar-toggle {
    display: none;
    font-size: 2rem;
    cursor: pointer;
}
  
/* Mobile responsiveness */
@media screen and (max-width: 768px) {
    .navbar-links {
        display: none;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 90px;
        background-color: var(--white);
        padding: 20px 0px;
        transform: translateX(-20px);
    }

    .navbar-links.open {
        display: flex;
    }

    .navbar-links a {
        margin: 10px 0;
        text-align: center;
    }

    .navbar-links a:last-child {
        margin: 20px;
    }

    .navbar-toggle {
        display: block;
    }
}
  
/* Dropdown */
.dropdown {
    position: relative;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #d9d9d9;
    min-width: 160px;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content a {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
}

.dropdown-content a:hover {
    background-color: #575757;
}