.contact-form {
    max-width: 500px;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    border: none !important;
}

.contact-form h2 {
    font-size: 1.75rem;
}

.contact-form p {
    color: var(--black);
}

.form-label {
    font-weight: 500;
}

@media screen and (max-width: 768px) {
    .contact-form {
        width: 100%;
        max-width: 100%;
    }
}