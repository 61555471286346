footer {
    background-color: white;
}

footer ul {
    margin-bottom: 0px;
}

.footer-address {
    margin-top: 20px;
}

.footer-main {
    padding: 30px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.footer-copyright {
    padding: 15px;
    background: linear-gradient(125deg, var(--purple), var(--aqua));
    color: var(--white);
}

.footer-section:not(:last-child) {
    border-right: 1px solid var(--grey);
}

.footer-section:not(:first-child) {
    text-align: center;
}
  
footer h5 {
    font-weight: bold;
    margin-bottom: 15px;
}
  
footer ul {
    padding-left: 0;
    list-style-type: none;
}

.footer-address {
    text-align: left;
}
  
footer a,
.privacy-policy button {
    text-decoration: none;
    transition: color 0.3s ease;
    color: var(--black);
}

footer a:hover,
.privacy-policy button:hover {
    color: var(--dark-purple);
}

@media screen and (max-width: 768px) {
    .footer-section {
        text-align: center;
    }

    .footer-address {
        text-align: center;
    }

    .footer-section .navbar-logo {
        justify-content: center;
    }

    .footer-section:not(:last-child) {
        border-right: none;
    }

    .footer-section:first-child {
        margin: auto;
    }

    .footer-section:last-child {
        margin-bottom: 0px !important;
    }
}

.privacy-policy {
    padding-top: 10px;
}

.privacy-policy button {
    border: none;
    background: none;
}