
.privacy-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    font-size: .9rem;
    color: var(--black);
}

.privacy-policy-header {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.privacy-policy-text h4 {
    font-size: 1.1rem;
    border-bottom: 1px solid var(--grey);
    padding: 10px 0px;
    margin-bottom: 10px;
    margin-top: 10px;
    color: var(--black);
}

.privacy-policy-text p {
    margin: 0;
}

.privacy-modal p:last-of-type {
    margin: 0;
}

.privacy-modal ul {
    padding-left: 15px;
    list-style-type: disc;
    margin-bottom: 8px;
}
  
.privacy-modal-content {
    background-color: white;
    padding: 20px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    position: relative;
    max-height: 75vh;
    overflow: auto;
    border-radius: 5px;
    text-align: left;
}
  
.close-modal {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 1.4rem;
    color: var(--black);
    cursor: pointer;
}
  
.close-modal:hover {
    color: var(--purple);
}

.privacy-banner .close-button {
    display: none;
}