.cookie-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: var(--white);
    color: #000;
    text-align: center;
    padding: 10px;
    z-index: 1000;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.cookie-banner p {
    margin: 0;
    display: inline-block;
    font-size: .85rem;
}

.cookie-banner .close-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 1.1rem;
    cursor: pointer;
    color: var(--black);
}

.cookie-banner .close-icon:hover {
    color: var(--purple);
}

.cookie-banner .learn-more {
    color: var(--black);
    text-decoration: underline;
    cursor: pointer;
}

.cookie-banner button {
    margin-left: 20px;
}

.cookie-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    font-size: .9rem;
    color: var(--black);
}

.cookie-modal h4:first-of-type {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.cookie-modal h4:not(:first-of-type) {
    font-size: 1.1rem;
    border-bottom: 1px solid var(--grey);
    padding: 10px 0px;
    margin-bottom: 10px;
    color: var(--black);
}

.cookie-modal p:last-of-type {
    margin: 0;
}

.cookie-modal ul {
    padding-left: 15px;
}
  
.cookie-modal-content {
    background-color: white;
    padding: 20px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    position: relative;
    max-height: 75vh;
    overflow: auto;
    border-radius: 5px;
}
  
.close-modal {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 1.4rem;
    color: var(--black);
    cursor: pointer;
}
  
.close-modal:hover {
    color: var(--purple);
}

.cookie-banner .close-button {
    display: none;
}

/* Mobile responsiveness */
@media (max-width: 1200px) {
    .cookie-banner {
        padding: 10px 15px;
        font-size: 0.9rem;
    }

    .cookie-banner p {
        margin-bottom: 15px;
    }

    .cookie-modal h4:first-of-type {
        font-size: 1.5rem;
    }

    .close-modal {
        font-size: 2rem;
        right: 15px;
    }

    .cookie-banner .close-button {
        display: block;
        margin-top: 10px;
        border: 2px solid var(--dark-purple);
        background-color: var(--white);
        color: var(--dark-purple);
    }

    .cookie-banner .close-icon {
        display: none;
    }

    .cookie-banner button {
        margin: auto;
        width: 50%;
        max-width: 150px;
    }
}